<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <h1 class="text-xl text-left font-extrabold pl-4">Organisation</h1>
      <div class="w-7/12 mt-1 ml-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div>
      <div class="mx-3 mt-6">
        <Card class="mt-6 p-5">
          <div class="flex mb-6" style="justify-content: flex-end">
            <Button class="text-white"
              :background="appearance.buttonColor || defaultBTNColor"
              @click="handleClick"
            >
              New Designation
            </Button>
          </div>
          <template v-if="!showError" v-slot:footer>
            <card-footer
              printcard
              reloadcard
              @print="print"
              @reload="reload()"
              @sortType="handleSort($event)"
              @option="handleOptionSelect($event)"
              @searchResult="handleSearch($event)"
            />
          </template>
        </Card>
      </div>
      <div v-if="!showError" class="p-3">
        <sTable
          :headers="designationHeader"
          :items="designationArray"
          style="width: 100%"
          aria-label="designationTable"
          :pagination-list="metaData"
          id="printMe"
          @page="handlePage($event)"
          @itemsPerPage="handleItemsPerPage($event)"
          page-sync
          :loading="loadingData"
          class="w-full"
          has-number
        >
          <template v-slot:item="{ item }">
            <span v-if="item.level">{{
              item.data.level ? item.data.level.name : "------"
            }}</span>
            <span v-else-if="item.reportsTo">{{
              item.data.reportsTo ? item.data.reportsTo.name : "------"
            }}</span>
            <span v-else-if="item.isSupervisory">{{
              item.data.isSupervisory ? "Supervisory" : "Non-Supervisory"
            }}</span>
            <span class="text-carrotOrange" v-else-if="item.orgFunction">
              <p class="text-darkPurple">
                {{
                  item.data.orgFunction ? item.data.orgFunction.name : "------"
                }}
              </p>
              {{
                item.data.orgFunction
                  ? item.data.orgFunction.hierarchy
                    ? item.data.orgFunction.hierarchy.name
                    : "------"
                  : "------"
              }}
            </span>
            <span class="text-carrotOrange" v-else-if="item.payGrades">
              <p class="text-darkPurple">
                {{
                  item.data.payGrades.paygrades[0]
                    ? item.data.payGrades.paygrades[0].paygrade.payGradeId
                    : "-----"
                }}
              </p>
              {{
                item.data.payGrades.paygrades[0]
                  ? convertToCurrency(
                      item.data.payGrades.paygrades[0].paygrade.rangeMinimum
                    )
                  : convertToCurrency(0)
              }}
              -
              {{
                item.data.payGrades.paygrades[0]
                  ? convertToCurrency(
                      item.data.payGrades.paygrades[0].paygrade.rangeMaximum
                    )
                  : convertToCurrency(0)
              }}
            </span>

            <span v-else-if="item.id">
              <Menu left top="-150" margin="24" class="my-2 p-0">
                <template v-slot:title>
                  <icon icon-name="more_icon" size="xs" />
                </template>
                <div style="width: 116px; height: 81px" class="py-3 px-2">
                  <div
                    class="flex py-1 px-3 more h-8 cursor-pointer"
                    @click="handleEdit(item.data.id)"
                  >
                    <icon
                      icon-name="edit"
                      class-name="text-blueCrayola mr-2"
                      size="xsm"
                    />
                    <p class="pt-1">Edit</p>
                  </div>
                  <div
                    class="flex px-3 py-1 more h-8 cursor-pointer"
                    @click="confirmDelete(item.data.id)"
                  >
                    <icon
                      icon-name="delete"
                      class-name="text-desire mr-2"
                      size="xsm"
                    />
                    <p class="pt-1">Delete</p>
                  </div>
                </div>
              </Menu>
            </span>
          </template>
        </sTable>
      </div>
      <div v-else>
        <error-page />
      </div>
    </div>
    <RightSideBar
      v-if="openModal"
      @submit="editMode ? updateDesignation() : createDesignation()"
      @close="(openModal = false), reset()"
      submit="Save"
      :loading="loading"
      :button-class="`bg-dynamicBackBtn text-white`"
      :disabled-button="disableBtn"
    >
      <template v-slot:title>
        <p class="text-darkPurple">
          {{ editMode ? "Edit Designation" : "Add New Designation" }}
        </p>
      </template>
      <template v-slot:subtitle>
        <p class="font-semibold text-darkPurple">
          Which of these two best describe the Designation?
        </p>
        <radio-button
          :options="categoryArray"
          col-span="col-span-6"
          class="m-4 text-darkPurple"
          row-gap="gap-y-5"
          v-model="designationCredentials.isSupervisory"
        />
      </template>
      <div>
        <p class="text-darkPurple">Designation Details</p>
        <div
          class="grid grid-cols-12 gap-2 gap-y-4 text-darkPurple"
          style="margin-top: 1.125rem"
        >
          <div class="col-span-12">
            <c-select
              placeholder="--Select--"
              :options="functions"
              @input="populateCostCenter"
              label="Functional Unit"
              variant="w-full"
              v-model="designationCredentials.functionId"
            />
          </div>
          <div class="col-span-12">
            <c-text
              placeholder="Automatically Loaded from Selected Function"
              disabled
              label="Cost Centre"
              variant="w-full"
              v-model="designationCredentials.costCentre"
            />
          </div>
          <div class="col-span-12">
            <c-text
              placeholder="--Enter Designation Name--"
              variant="w-full "
              label="Job Title"
              v-model="designationCredentials.name"
            />
          </div>
          <div class="col-span-12">
            <c-select
              placeholder="--Select--"
              :options="levelsArray"
              label="Job Level"
              variant="w-full"
              v-model="getLevel"
            />
          </div>
          <div class="col-span-12">
            <c-select
              placeholder="--Select--"
              :options="[
                { id: 'true', name: 'Yes' },
                { id: 'false', name: 'No' },
              ]"
              label="Eligible for Overtime?"
              variant="w-full"
              v-model="designationCredentials.enabledOvertime"
            />
          </div>
          <div class="col-span-12">
            <c-select
              placeholder="--Select--"
              :options="supervisoryDesignation"
              variant="w-full"
              label="Reporting line"
              v-model="designationCredentials.reportingTo"
            />
          </div>
          <p class="col-span-12 text-flame">
            {{ error }}
          </p>
        </div>
      </div>
    </RightSideBar>
    <Modal v-if="dialogDel">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this Designation?
          </p>
          <div class="flex">
            <Button class="text-white"
              :background="appearance.buttonColor || defaultBTNColor"
              @click="deleteDesignation"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import { clone } from "lodash";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import ErrorPage from "@/modules/Admin/error403";
import CardFooter from "@/components/CardFooter";
import Card from "@/components/Card";
import Button from "@/components/Button";
import Menu from "@/components/Menu";
import RightSideBar from "@/components/RightSideBar";
import RadioButton from "@/components/RadioButton";
import Modal from "@/components/Modal";

export default {
  name: "Designation",
  components: {
    Button,
    Card,
    CardFooter,
    Breadcrumb,
    ErrorPage,
    CText,
    CSelect,
    Menu,
    STable,
    RightSideBar,
    Modal,
    RadioButton,
  },
  data() {
    return {
      disableBtn: false,
      designationArray: [],
      bandError: "",
      openModal: false,
      editMode: false,
      showError: false,
      dialogDel: false,
      designationId: "",
      min: "",
      max: "",
      bandName: "",
      loadingData: true,
      loading: false,
      itemsPerPage: null,
      numberOfPage: null,
      searchWord: null,
      isActive: null,
      sortType: null,
      metaData: {},
      functions: [],
      designations: [],
      hierarchy: [],
      levelsArray: [],
      getLevel: "",
      payBandSetup: false,
      payRange: null,
      payGrade: null,
      timeout: null,
      error: "",
      designationCredentials: {
        name: "",
        isSupervisory: true,
        functionId: null,
        reportingTo: null,
        dottedReportingTo: null,
        enabledOvertime: null,
        levelId: null,
        costCentre: null,
      },
      breadcrumbs: [
        {
          disabled: false,
          text: "Organisation",
          id: "",
        },
        {
          disabled: false,
          text: "Designations",
          id: "Designations",
        },
      ],
      categoryArray: [
        { name: "Supervisory", radioName: "designation", value: true },
        { name: "Non-Supervisory", radioName: "designation", value: false },
      ],
    };
  },
  watch: {
    getLevel(val) {
      this.designationCredentials.levelId = val;
      this.getLevelPayBand(val);
    },
  },
  methods: {
    getLevelPayBand(level) {
      this.$_getLevelPayBand(level).then((result) => {
        this.payBandSetup = result.data.isSetup;
        if (!this.payBandSetup) {
          this.bandError = "No PayBand for this Job Level";
        } else {
          this.max = this.convertToCurrency(result.data.payband.upperBand);
          this.min = this.convertToCurrency(result.data.payband.lowerBand);
          this.bandName = result.data.payband.name;
        }
      });
    },
    reset() {
      this.designationCredentials = {
        name: "",
        isSupervisory: true,
        functionId: null,
        reportingTo: null,
        dottedReportingTo: null,
        levelId: null,
        costCentre: null,
      };
      this.min = "";
      this.editMode = false;
      this.max = "";
      this.getLevel = "";
      this.payBandSetup = false;
    },
    checkBound(firstValue, secondValue) {
      if (
        firstValue !== null ||
        firstValue !== 0 ||
        secondValue !== null ||
        secondValue !== 0
      ) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.error = "";
          let valueOne = this.handleSplit(firstValue);
          let valueTwo =
            typeof secondValue === "string"
              ? this.handleSplit(secondValue)
              : secondValue;
          valueOne = Number(valueOne);
          valueTwo = Number(valueTwo);
          if (valueOne > valueTwo) {
            this.error = "Minimum value can't be higher than Maximum value";
          }
        }, 1000);
      }
    },
    async confirmDelete(id) {
      try {
        await this.$handlePrivilege("designation", "deleteDesignation");
        this.designationId = id;
        this.dialogDel = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        })
      }
    },
    async deleteDesignation() {
      try{
      this.dialogDel = false;
      this.loadingData = true;
      await this.$_deleteDesignation([this.designationId]);
      this.$toasted.success("Designation deleted successfully", {
        duration: 3000,
      });
      await this.getDesignation("");
      }
      catch (e) {
        this.$toasted.error(e, { duration: 10000 })
        this.loadingData = false
      }
    },
    async handleClick() {
      try {
        await this.$handlePrivilege("designation", "addNewDesignation");
        this.openModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    handleSplit(value) {
      return value.split(",").join("");
    },
    async updateDesignation() {
      this.disableBtn = true;
      if (!this.error) {
        this.designationCredentials.lowerAnnualSalaryBand = Number(
          this.handleSplit(this.min)
        );
        this.designationCredentials.upperAnnualSalaryBand = Number(
          this.handleSplit(this.max)
        );
        if (this.designationCredentials.reportingTo === "null") {
          this.designationCredentials.reportingTo = null;
        }
        this.designationCredentials.id = this.designationId;
        try {
          await this.$_updateDesignation(this.designationCredentials);
          this.openModal = false;
          this.$router.push({
            name: "designation",
          });
          this.$toasted.success("Designation updated successfully", {
            duration: 3000,
          });
          this.getDesignation("");
          this.reset();
          this.disableBtn = false;
        } catch (error) {
          this.$toasted.error("Designation not updated successfully", {
            duration: 3000,
          });
          this.disableBtn = false;
          throw new Error(error);
        }
      }
    },
    async handleEdit(id) {
      try {
        await this.$handlePrivilege("designation", "editDesignation");
        this.loading = true;
        this.openModal = true;
        this.editMode = true;
        this.$_getOneDesignations(id).then((result) => {
          this.designationCredentials = result.data.designation;
          if (this.designationCredentials.lowerAnnualSalaryBand) {
            this.min = this.convertToCurrency(
              this.designationCredentials.lowerAnnualSalaryBand
            );
          }
          if (this.designationCredentials.upperAnnualSalaryBand) {
            this.max = this.convertToCurrency(
              this.designationCredentials.upperAnnualSalaryBand
            );
          }
          this.getLevel = result.data.designation.levelId;
          this.designationId = id;
          this.loading = false;
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async createDesignation() {
      this.disableBtn = true;
      if (!this.error) {
        this.designationCredentials.lowerAnnualSalaryBand = Number(
          this.handleSplit(this.min)
        );
        this.designationCredentials.upperAnnualSalaryBand = Number(
          this.handleSplit(this.max)
        );
        if (this.designationCredentials.reportingTo === "null") {
          this.designationCredentials.reportingTo = null;
        }
        this.designationCredentials.orgId = this.$AuthUser.orgId;
        this.designationCredentials.createdBy = this.$AuthUser.id;
        try {
          await this.$_createDesignation(this.designationCredentials);
          this.loadingData = true;
          this.reset();
          this.$toasted.success("Designation created successfully", {
            duration: 3000,
          });
          this.getDesignation("");
          this.openModal = false;
          this.disableBtn = false;
        } catch (error) {
          this.$toasted.error("Designation not created successfully", {
            duration: 3000,
          });
          this.disableBtn = false;
          this.reset();
        }
      }
    },
    queryUpdate() {
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getDesignation(`${sort}${search}${pageNumber}${itemPage}`);
    },
    async print() {
      await this.$htmlToPaper("printMe");
    },
    handleSearch(searchWord) {
      this.loadingData = true;
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    handleSort(sortType) {
      this.loadingData = true;
      const changeSortType = sortType.split(":");
      let result;
      if (changeSortType[0] === "createdAt") {
        result = sortType;
      } else {
        result = `name:${changeSortType[1]}`;
      }
      this.sortType = result;
      this.queryUpdate();
    },
    reload() {
      this.loadingData = true;
      this.getDesignation("");
    },
    async getFunctions() {
      try {
        const response = await this.$_getFunction(this.$orgId);
        this.functions = response.data.functions;
      } catch (error) {
        throw new Error(error);
      }
    },
    async getLevels() {
      try {
        const response = await this.$_getLevels("");
        this.levelsArray = response.data.levels;
      } catch (error) {
        throw new Error(error);
      }
    },
    async getDesignation(payload) {
      try {
        const response = await this.$_getDesignationsWithPayband(payload);
        this.designationArray = clone(response.data.designations);
        this.designations = response.data.designations;
        this.metaData = response.data.meta;
        this.loadingData = false;
        this.designations.push({ name: "Nil", id: "null" });
      } catch (error) {
        throw new Error(error);
      }
    },
    populateCostCenter(value) {
      const result = this.functions.find((element) => element.id === value);
      this.designationCredentials.costCentre = result.costCenter;
    },
  },
  computed: {
    filteredFunctions() {
      return this.$store.state.org.getAllFunctions;
    },
    supervisoryDesignation() {
      return this.designations.filter(item => item.isSupervisory)
    },
    designationHeader() {
      return [
        { title: "DESIGNATION", value: "name", width: "20" },
        { title: "TYPE", value: "isSupervisory", width: "15" },
        { title: "JOB LEVEL ID", value: "level", width: "15" },
        { title: "FUNCTIONAL UNIT", value: "orgFunction", width: "15" },
        { title: "REPORTING LINE", value: "reportsTo", width: "15" },
        { title: "Pay Info", value: "payGrades", width: "30" },
        { title: "", value: "id", image: true },
      ];
    },
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  async mounted() {
    try {
      await this.$handlePrivilege("designation", "viewRegister");
      this.showError = false
      this.getFunctions();
      this.getLevels();
      this.getDesignation("");
    } catch (error) {
      this.showError = true
      this.loadingData = false;
    }
  },
};
</script>

<style scoped>
.custom-radius {
  border-radius: 7px;
}
</style>

<style>
.bg-fadeFlame {
  background-color: rgba(241, 90, 41, 0.5);
}
</style>
